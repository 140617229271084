<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/service-technician"><a>Kartoteki</a></router-link>
          <router-link tag="li" to="/records/service-technician"><a>Serwisanci</a></router-link>
          <router-link tag="li" to="/records/service-technician/add" class="is-active"><a>Dodaj nowego serwisanta</a></router-link>
        </ul>   
      </nav>
    </portal-target>

    <ServiceTechnicianForm />
  </section>
</template>

<script>
  import ServiceTechnicianForm from '@/views/Records/ServiceTechnician/ServiceTechnicianForm'
  export default {
    name: 'ServiceTechnicianAdd',
    components: {
      ServiceTechnicianForm
    },
  }
</script>

<style lang="scss" scoped>

</style>