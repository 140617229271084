<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/service-technician"><a>Kartoteki</a></router-link>
          <router-link tag="li" to="/records/service-technician"><a>Serwisanci</a></router-link>
          <router-link tag="li" :to="{ name: 'ServiceTechnicianDetails', params: { id: this.$route.params.id }}" class="is-active"><a>{{ name }}</a></router-link>
        </ul>   
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div  id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <router-link tag="a" to="/records/service-technician/" class="button xbtn is-medium is-transparent"><icon name="x"></icon></router-link>
            <h2 class="title level-left">{{ name }}</h2>
          </div>
          <div class='buttons level-right'>
            <button @click="userRemove" class="button is-medium is-light"><icon name="trash"></icon><span>Usuń</span></button>
            <router-link tag="a" :to="{ name: 'ServiceTechnicianEdit', params: { id: parseInt(this.$route.params.id) }}" class="button is-medium is-light"><icon name="edit"></icon><span>Edytuj</span></router-link>
            <a v-if="selectATechnician" @click="selectTechnician" class="button is-medium is-dark"><icon name="check"></icon><span>Wybierz</span></a>
          </div>          
        </div>
      </fixed-header> 
    </div> 
    <div class="ym-whitebg pa40" v-if="technican">
        <div class="columns is-variable is-6">
            <div class="column is-6" v-if="technican.firmname">
                <p class="label-small">Pełna nazwa klienta</p>
                <p class="label is-uppercase">{{ technican.firmname }}</p>
            </div>
            <div class="column is-6" v-if="technican.nip">
                <p class="label-small">NIP</p>
                <p class="label is-uppercase">{{ technican.nip }}</p>
            </div>
        </div>
        <div class="columns is-variable is-6">
            <div class="column is-6" v-if="technican.firstname">
                <p class="label-small">Imię i nazwisko</p>
                <p class="label is-uppercase">{{ technican.firstname }} {{ technican.lastname }}</p>
            </div>
        </div>

        <div class="columns is-variable is-6">
            <div class="column is-6" v-if="technican.address">
                <p class="label-small">Ulica</p>
                <p class="label is-uppercase">{{ technican.address }}
                <br>{{ technican.postcode }} {{ technican.city }} 
                </p>
            </div>
            <div class="column is-6" v-if="technican.phone">
                <p class="label-small">Telefon</p>
                <p class="label is-uppercase">{{ technican.phone }}</p>
            </div>
        </div>

        <div class="columns is-variable is-6">
            <div class="column is-6" v-if="technican.contact_person">
                <p class="label-small">Osoba kontaktowa</p>
                <p class="label is-uppercase">{{ technican.contact_person }}</p>
            </div>
            <div class="column is-6" v-if="technican.site">
                <p class="label-small">Strona www</p>
                <p class="label is-lowercase"><a :href="technican.site" rel="noopener" target="_blank">{{ technican.site }}</a></p>
            </div>
        </div>

        <div class="columns is-variable is-6">
            <div class="column is-6" v-if="technican.code">
                <p class="label-small">Numer referencyjny</p>
                <p class="label is-uppercase">{{ technican.code }}</p>
            </div>
            <div class="column is-6" v-if="technican.email">
                <p class="label-small">E-mail</p>
                <p class="label is-lowercase"><a :href="'mailto:'+technican.email">{{ technican.email }}</a></p>
            </div>
        </div>
    </div>        
  </section>
</template>

<script>
  import FixedHeader from 'vue-fixed-header'
  import {mapActions, mapGetters} from 'vuex'
  import YMmodal from '@/components/Modal.vue';

  export default {
    name: 'ServiceTechnicianDetails',
    components: {
      FixedHeader
    },
    data: function () {
      return {
        technican: {
          firstname: '',
          lastname: ''
        }
      }
    }, 
    mounted() {
      this.getUser()
    },
    computed: {
      ...mapGetters({
        selectATechnician: 'orders/selectATechnician',
        order: 'orders/order'
      }),       
      name: function () {
        return this.technican.firstname + ' ' + this.technican.lastname
      }
    }, 
    methods: {
      ...mapActions({
        getUserCall: 'records/getUser',
        removeUserCall: 'records/removeUser',
      }),
      getUser() {
        this.getUserCall({id: this.$route.params.id})
          .then(resp => this.technican = resp)
          .catch((error) => {
            this.technican = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },
      userRemove() {
        this.$buefy.modal.open({
          parent: this,
          component: YMmodal,
          hasModalCard: true,
          props: {
            title: 'Czy na pewno chcesz usunąć użytkownika?',
            content: `<span class='is-danger'>Usunięcia użytkownika nie można cofnąć.</span> Użytkownik pozostanie w utworzonych do tej pory zleceniach.`,
            cancel: 'Anuluj',
            ymHasIcon: true,
            ymIcon: 'trash',
            submit: `<span class="mal50i">Usuń</span>`,
            ymConfirm: true,
              ymOnConfirm: () => {
                this.removeUserCall({id: this.$route.params.id})
                .then(({ data }) => {
                  document.querySelector('.is-cancel').click()
                  this.$router.push({name: 'ServiceTechnician'})
                  this.$buefy.toast.open({
                      duration: 5000,
                      message: data.alert[1],
                      type: 'is-success',
                      position: 'is-bottom'
                  })  
                })
                .catch(() => {
                  document.querySelector('.is-cancel').click()
                })                 
            }
          }
        }) 
      }, 
      selectTechnician() {
        this.$store.dispatch('orders/setTechnician', this.technican)
          .then(() => {
            if(this.order && this.order.id) {
              this.$router.push({ name: 'ServiceEdit', params: { id: parseInt(this.order.id) } })
            } else {
              this.$router.push({ name: 'ServiceAdd' })
            }
          })  
      }               
    }               
  }
</script>

<style lang="scss" scoped>

</style>